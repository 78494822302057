.container {
  margin-bottom: 20px;
  margin-right: 20px;
}
@media (min-width: 767px) {
  .container {
    max-width: 278px;
  }
}
@media (max-width: 766px) {
  .container {
    width: 100%;
  }
}

.scheduleType {
  width: fit-content;
  font-size: 0.9rem;
}

.imageContainer {
  height: 140px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.detailsContainer {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.title {
  font-size: 0.9rem;
}

.dataLabel {
  font-size: 0.8rem;
}

.attendedCustomersDataLabel {
  flex-basis: auto;
}

.attendedCustomersTooltip {
  margin-top: -3px;
}

.tooltipPlacementBottom {
  margin: 10px 0 !important;
}

.totalCustomers {
  color: #493ab1;
}

.attendedCustomers {
  color: #00b77a;
}
