@media screen and (max-width: 766px) {
  .breadcrumbs {
    font-size: 1.1rem !important;
  }
}

@media screen and (min-width: 767px) {
  .breadcrumbs {
    font-size: 1.2rem !important;
  }
}

.breadcrumbs {
  font-weight: bold !important;
}
.tooltipPlacementBottom {
  margin: 10px 0 !important;
}

.gap16 {
  gap: 16px !important;
}
