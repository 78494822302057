.root {
  width: 80%;
  max-width: unset !important;
}

.section {
  overflow: hidden;
  margin: 20px 0;
}

.activity {
  font-size: 16px;
  font-weight: 500;
}

@media (min-width: 767px) {
  .headerContainer {
    padding: 16px 24px;
  }

  .avatar {
    width: 40px !important;
    height: 40px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .section {
    margin: 20px 0;
  }
}
@media (max-width: 766px) {
  .headerContainer {
    padding: 12px 19px;
  }

  .avatar {
    width: 32px !important;
    height: 32px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .section {
    margin: 15px 0;
  }
}
