.linkButton {
  background-color: transparent !important;
  height: unset !important;
  min-width: unset !important;
  color: rgb(73, 58, 177) !important;
}

.infoIcon {
  color: white;
  background-color: black;
  border-radius: 100000px;
}
