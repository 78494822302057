@media screen and (max-width: 766px) {
  .breadcrumbs {
    font-size: 1.1rem !important;
  }
}

@media screen and (min-width: 767px) {
  .breadcrumbs {
    font-size: 1.2rem !important;
  }
}

.tooltipPlacementBottom {
  margin: 10px 0 !important;
}

.sessionWrapper {
  color: #493ab1;
  text-decoration: underline;
}

.sessionWrapper:hover {
  cursor: pointer;
}

.breadcrumbs {
  font-weight: bold !important;
}
